import { ConfigStateService, featuresFactory, RoutesService, noop } from '@abp/ng.core';
import { InjectionToken, inject, APP_INITIALIZER } from '@angular/core';
import { setModuleVisibilityFactory } from '@volo/abp.commercial.ng.ui/config';

const TEXT_TEMPLATE_MANAGEMENT_FEATURES = new InjectionToken('TEXT_TEMPLATE_MANAGEMENT_FEATURES', {
    providedIn: 'root',
    factory: () => {
        const configState = inject(ConfigStateService);
        const featureKey = 'TextManagement.Enable';
        const mapFn = features => ({
            enable: features[featureKey].toLowerCase() !== 'false',
        });
        return featuresFactory(configState, [featureKey], mapFn);
    },
});
const SET_TEXT_TEMPLATE_MANAGEMENT_ROUTE_VISIBILITY = new InjectionToken('SET_TEXT_TEMPLATE_MANAGEMENT_ROUTE_VISIBILITY', {
    providedIn: 'root',
    factory: () => {
        const routes = inject(RoutesService);
        const stream = inject(TEXT_TEMPLATE_MANAGEMENT_FEATURES);
        setModuleVisibilityFactory(stream, routes, "TextTemplateManagement::Menu:TextTemplates" /* TextTemplates */).subscribe();
    },
});
const ɵ0 = noop;
const TEXT_TEMPLATE_MANAGEMENT_FEATURES_PROVIDERS = [
    {
        provide: APP_INITIALIZER,
        useFactory: ɵ0,
        deps: [SET_TEXT_TEMPLATE_MANAGEMENT_ROUTE_VISIBILITY],
        multi: true,
    },
];

/**
 * Generated bundle index. Do not edit.
 */

export { SET_TEXT_TEMPLATE_MANAGEMENT_ROUTE_VISIBILITY, TEXT_TEMPLATE_MANAGEMENT_FEATURES, TEXT_TEMPLATE_MANAGEMENT_FEATURES_PROVIDERS, ɵ0 };
