import { AbstractNgModelComponent, PagedAndSortedResultRequestDto, CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { UiExtensionsModule } from '@abp/ng.theme.shared/extensions';
import { Component, Input, Injector, EventEmitter, Output, ViewEncapsulation, NgModule, InjectionToken } from '@angular/core';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgControl, FormsModule } from '@angular/forms';
import { finalize, debounceTime, distinctUntilChanged, tap, switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { CommonModule } from '@angular/common';

class AbstractLookupSelectComponent extends AbstractNgModelComponent {
    constructor() {
        super(...arguments);
        this.lookupNameProp = 'displayName';
        this.lookupIdProp = 'id';
        this.maxResultCount = 10;
        this.skipCount = 0;
        this.disabled = false;
    }
    get pageQuery() {
        return this.createRequestDto(this);
    }
    set pageQuery(value) {
        Object.assign(this, this.createRequestDto(value));
    }
    createRequestDto(value) {
        return new PagedAndSortedResultRequestDto({
            maxResultCount: value.maxResultCount,
            skipCount: value.skipCount,
            sorting: value.sorting,
        });
    }
}
AbstractLookupSelectComponent.decorators = [
    { type: Component, args: [{ template: '' },] }
];
AbstractLookupSelectComponent.propDecorators = {
    getFn: [{ type: Input }],
    lookupNameProp: [{ type: Input }],
    lookupIdProp: [{ type: Input }],
    maxResultCount: [{ type: Input }],
    skipCount: [{ type: Input }],
    sorting: [{ type: Input }],
    disabled: [{ type: Input }],
    cid: [{ type: Input }]
};

class LookupInputComponent extends AbstractNgModelComponent {
    constructor(injector) {
        super(injector);
        this.lookupNameProp = 'displayName';
        this.displayNameProp = 'name';
        this.pickButton = { show: true, disabled: false };
        this.clearButton = { show: true, disabled: false };
        this.selectedName = '';
        this.ngControl = injector.get(NgControl);
        this.ngControl.valueAccessor = this;
    }
    get isInvalid() {
        return this.ngControl.dirty && this.ngControl.invalid;
    }
    ngAfterViewInit() {
        if (this.value && this.editingData && this.editingData[this.displayNameProp]) {
            this.selectedName = this.editingData[this.displayNameProp];
        }
    }
    pick(data) {
        this.value = data.id;
        this.selectedName = data[this.lookupNameProp];
        this.isModalVisible = false;
    }
    onClickPick() {
        this.isModalVisible = true;
    }
    onClickClear() {
        this.value = '';
        this.selectedName = '';
    }
    writeValue(value) {
        if (!value)
            this.selectedName = '';
        super.writeValue(value);
    }
}
LookupInputComponent.decorators = [
    { type: Component, args: [{
                selector: 'abp-lookup-input',
                template: "<div class=\"input-group\">\n  <input\n    [id]=\"cid\"\n    type=\"text\"\n    class=\"form-control\"\n    disabled\n    [ngModel]=\"selectedName\"\n    [class.input-validation-error]=\"isInvalid\"\n  />\n  <div class=\"input-group-append\">\n    <button\n      *ngIf=\"pickButton.show\"\n      [disabled]=\"pickButton.disabled\"\n      class=\"btn btn-info\"\n      type=\"button\"\n      (click)=\"onClickPick()\"\n    >\n      {{ { key: '::Pick', defaultValue: 'Pick' } | abpLocalization }}\n    </button>\n    <button\n      *ngIf=\"clearButton.show\"\n      [disabled]=\"clearButton.disabled\"\n      class=\"btn btn-danger\"\n      type=\"button\"\n      (click)=\"onClickClear()\"\n    >\n      <i class=\"fa fa-times\"></i>\n    </button>\n  </div>\n</div>\n\n<abp-modal [(visible)]=\"isModalVisible\">\n  <ng-template #abpHeader>\n    <h3>{{ { key: '::Pick', defaultValue: 'Pick' } | abpLocalization }}</h3>\n  </ng-template>\n\n  <ng-template #abpBody>\n    <abp-lookup-table\n      [getFn]=\"getFn\"\n      [lookupNameProp]=\"lookupNameProp\"\n      (pick)=\"pick($event)\"\n    ></abp-lookup-table>\n  </ng-template>\n\n  <ng-template #abpFooter>\n    <button type=\"button\" class=\"btn btn-secondary\" abpClose>\n      {{ 'AbpUi::Cancel' | abpLocalization }}\n    </button>\n  </ng-template>\n</abp-modal>\n"
            },] }
];
LookupInputComponent.ctorParameters = () => [
    { type: Injector }
];
LookupInputComponent.propDecorators = {
    getFn: [{ type: Input }],
    lookupNameProp: [{ type: Input }],
    displayNameProp: [{ type: Input }],
    editingData: [{ type: Input }],
    pickButton: [{ type: Input }],
    clearButton: [{ type: Input }],
    cid: [{ type: Input }]
};

class LookupSelectComponent extends AbstractLookupSelectComponent {
    constructor(injector) {
        super(injector);
        this.maxResultCount = 1000;
        this.datas = [];
        this.ngControl = injector.get(NgControl);
        this.ngControl.valueAccessor = this;
    }
    get isInvalid() {
        return this.ngControl.dirty && this.ngControl.invalid;
    }
    ngOnInit() {
        this.get();
    }
    get() {
        this.getFn(this.pageQuery).subscribe(({ items }) => {
            this.datas = items;
        });
    }
}
LookupSelectComponent.decorators = [
    { type: Component, args: [{
                selector: 'abp-lookup-select',
                template: "<select\n  [id]=\"cid\"\n  class=\"custom-select form-control\"\n  [(ngModel)]=\"value\"\n  [abpDisabled]=\"disabled\"\n  [class.input-validation-error]=\"isInvalid\"\n>\n  <option [ngValue]=\"\"></option>\n  <option *ngFor=\"let data of datas\" [ngValue]=\"data[lookupIdProp]\">\n    {{ data[lookupNameProp] }}\n  </option>\n</select>\n"
            },] }
];
LookupSelectComponent.ctorParameters = () => [
    { type: Injector }
];
LookupSelectComponent.propDecorators = {
    maxResultCount: [{ type: Input }]
};

class LookupTableComponent {
    constructor() {
        this.lookupNameProp = 'displayName';
        this.pick = new EventEmitter();
        this.data = [];
        this.totalCount = 0;
        this.pageQuery = { maxResultCount: 10, skipCount: 0, filter: '' };
    }
    ngOnInit() {
        this.get();
    }
    get() {
        this.loading = true;
        this.getFn(this.pageQuery)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(({ totalCount, items }) => {
            this.data = items;
            this.totalCount = totalCount;
        });
    }
    pageChange(page) {
        this.pageQuery.skipCount = this.pageQuery.maxResultCount * (page - 1);
        this.get();
    }
}
LookupTableComponent.decorators = [
    { type: Component, args: [{
                selector: 'abp-lookup-table',
                template: "<div class=\"card\">\n  <div class=\"card-body\">\n    <div id=\"data-tables-table-filter\" class=\"data-tables-filter\">\n      <label\n        ><input\n          type=\"search\"\n          class=\"form-control\"\n          [placeholder]=\"'AbpUi::PagerSearch' | abpLocalization\"\n          [(ngModel)]=\"pageQuery.filter\"\n          (input.debounce)=\"get()\"\n      /></label>\n    </div>\n    <abp-table\n      [abpLoading]=\"loading\"\n      [abpLoadingDelay]=\"500\"\n      [colgroupTemplate]=\"tableColgroup\"\n      [bodyTemplate]=\"tableBody\"\n      [value]=\"data\"\n      [rows]=\"pageQuery.maxResultCount\"\n      [totalRecords]=\"totalCount\"\n      [scrollable]=\"true\"\n      (pageChange)=\"pageChange($event)\"\n    >\n    </abp-table>\n    <ng-template #tableColgroup>\n      <colgroup>\n        <col [ngStyle]=\"{ 'width.px': 150 }\" />\n      </colgroup>\n    </ng-template>\n    <ng-template #tableBody let-data>\n      <tr>\n        <td class=\"text-center\">\n          <button class=\"btn btn-primary btn-sm\" type=\"button\" (click)=\"pick.emit(data)\">\n            {{ '::Pick' | abpLocalization }}\n          </button>\n        </td>\n        <td>{{ data[lookupNameProp] }}</td>\n      </tr>\n    </ng-template>\n  </div>\n</div>\n"
            },] }
];
LookupTableComponent.propDecorators = {
    getFn: [{ type: Input }],
    lookupNameProp: [{ type: Input }],
    pick: [{ type: Output }]
};

class LookupTypeaheadComponent extends AbstractLookupSelectComponent {
    constructor(injector) {
        super(injector);
        this.displayNameProp = 'name';
        this.lookupFilterProp = 'filter';
        this.filter = '';
        this.createItemToOptionMapper = (nameProp) => (item) => ({
            [this.lookupNameProp]: item[nameProp],
            [this.lookupIdProp]: item[this.lookupIdProp],
        });
        this.mapDataToOption = (item) => this.createItemToOptionMapper(this.displayNameProp)(item);
        this.mapResponseToOptions = ({ items }) => items.map(this.createItemToOptionMapper(this.lookupNameProp));
        this.search = (text$) => text$
            ? text$.pipe(debounceTime(300), distinctUntilChanged(), tap(text => (this.filter = text)), switchMap(text => (text ? this.getFn(this.pageQuery) : of({ items: [] }))), map(this.mapResponseToOptions))
            : of([]);
        this.typeaheadFormatter = (option) => option[this.lookupNameProp];
        this.ngControl = injector.get(NgControl);
        this.ngControl.valueAccessor = this;
    }
    get model() {
        return this._model;
    }
    set model(selectedOption) {
        this._model = selectedOption;
        const value = selectedOption ? selectedOption[this.lookupIdProp] : null;
        if (value !== this.value)
            this.value = value;
    }
    get isInvalid() {
        return this.ngControl.dirty && this.ngControl.invalid;
    }
    createRequestDto(value) {
        return Object.assign(new PagedAndSortedResultRequestDto({
            maxResultCount: value.maxResultCount,
            skipCount: value.skipCount,
            sorting: value.sorting,
        }), { [this.lookupFilterProp]: value.filter });
    }
    ngOnChanges({ editingData }) {
        if (!editingData)
            return;
        const data = editingData.currentValue;
        this.model = data ? this.mapDataToOption(data) : undefined;
    }
    writeValue(value) {
        if (!value)
            this._model = undefined;
        super.writeValue(value);
    }
}
LookupTypeaheadComponent.decorators = [
    { type: Component, args: [{
                selector: 'abp-lookup-typeahead',
                template: "<div class=\"position-relative\">\n  <input\n    #typeahead\n    [id]=\"cid\"\n    [abpDisabled]=\"disabled\"\n    [ngbTypeahead]=\"search\"\n    [editable]=\"false\"\n    [inputFormatter]=\"typeaheadFormatter\"\n    [resultFormatter]=\"typeaheadFormatter\"\n    [(ngModel)]=\"model\"\n    (blur)=\"value || (typeahead.value = null)\"\n    class=\"form-control\"\n    [class.input-validation-error]=\"isInvalid\"\n  />\n</div>\n"
            },] }
];
LookupTypeaheadComponent.ctorParameters = () => [
    { type: Injector }
];
LookupTypeaheadComponent.propDecorators = {
    displayNameProp: [{ type: Input }],
    lookupFilterProp: [{ type: Input }],
    filter: [{ type: Input }],
    editingData: [{ type: Input }]
};
function selfFactory(dependency) {
    return dependency;
}

class EntityFilterComponent {
}
EntityFilterComponent.decorators = [
    { type: Component, args: [{
                selector: 'abp-entity-filter',
                template: "<div class=\"card\">\n  <div class=\"card-body\">\n    <div class=\"row\">\n      <div class=\"col\">\n        <div class=\"input-group\">\n          <input\n            type=\"search\"\n            class=\"form-control\"\n            [placeholder]=\"'AbpUi::PagerSearch' | abpLocalization\"\n            [(ngModel)]=\"list.filter\"\n          />\n          <div class=\"input-group-append\">\n            <button class=\"btn btn-primary\" (click)=\"list.get()\">\n              <i class=\"fas fa-search\"></i>\n            </button>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n",
                encapsulation: ViewEncapsulation.None
            },] }
];
EntityFilterComponent.propDecorators = {
    list: [{ type: Input }]
};

class EntityFilterModule {
}
EntityFilterModule.decorators = [
    { type: NgModule, args: [{
                declarations: [EntityFilterComponent],
                imports: [CommonModule, CoreModule, FormsModule],
                exports: [EntityFilterComponent],
            },] }
];

class BaseCommercialUiModule {
}
BaseCommercialUiModule.decorators = [
    { type: NgModule, args: [{
                exports: [
                    AbstractLookupSelectComponent,
                    LookupTableComponent,
                    LookupInputComponent,
                    LookupSelectComponent,
                    LookupTypeaheadComponent,
                    EntityFilterModule,
                    UiExtensionsModule,
                ],
                declarations: [
                    AbstractLookupSelectComponent,
                    LookupTableComponent,
                    LookupInputComponent,
                    LookupSelectComponent,
                    LookupTypeaheadComponent,
                ],
                imports: [
                    CoreModule,
                    ThemeSharedModule,
                    UiExtensionsModule,
                    NgbTypeaheadModule,
                    EntityFilterModule,
                ],
            },] }
];
class CommercialUiModule {
}
CommercialUiModule.decorators = [
    { type: NgModule, args: [{
                exports: [BaseCommercialUiModule],
                imports: [BaseCommercialUiModule],
            },] }
];

const SHOW_ENTITY_HISTORY = new InjectionToken('SHOW_ENTITY_HISTORY');

/**
 * Generated bundle index. Do not edit.
 */

export { BaseCommercialUiModule, CommercialUiModule, EntityFilterComponent, EntityFilterModule, LookupInputComponent, LookupSelectComponent, LookupTableComponent, SHOW_ENTITY_HISTORY, AbstractLookupSelectComponent as ɵa, LookupTypeaheadComponent as ɵb };
