import * as i2 from '@abp/ng.core';
import { uuid, TrackByService, PROJECTION_STRATEGY, ContentProjectionService, RoutesService, CoreModule } from '@abp/ng.core';
import { collapse, ThemeSharedModule } from '@abp/ng.theme.shared';
import * as i0 from '@angular/core';
import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, Injectable, APP_INITIALIZER, NgModule } from '@angular/core';
import * as i1 from '@volo/abp.ng.audit-logging';
import { EntityChangeType, AuditLogsService, SHOW_ENTITY_DETAILS } from '@volo/abp.ng.audit-logging';
import { SHOW_ENTITY_HISTORY } from '@volo/abp.commercial.ng.ui';
import { AUDIT_LOGGING_FEATURES_PROVIDERS } from '@volo/abp.ng.audit-logging/common';

class EntityChangeDetailsComponent {
    constructor(track) {
        this.track = track;
        this.isCollapsed = false;
        this.colors = {
            [EntityChangeType.Created]: 'success',
            [EntityChangeType.Updated]: 'info',
            [EntityChangeType.Deleted]: 'danger',
        };
        this.uuid = uuid();
    }
    set itemWithUserName(item) {
        this.changeType = EntityChangeType[item.entityChange.changeType];
        this.color = this.colors[item.entityChange.changeType];
        this.userName = item.userName;
        this.entityChange = item.entityChange;
    }
    getPropColor(propertyChange) {
        return this.entityChange.changeType === EntityChangeType.Updated &&
            propertyChange.newValue !== propertyChange.originalValue
            ? 'red'
            : undefined;
    }
    getInterpolationParams(changeTime) {
        return [this.changeType, changeTime, this.userName];
    }
}
EntityChangeDetailsComponent.decorators = [
    { type: Component, args: [{
                selector: 'abp-entity-change-details',
                template: "<div\n  class=\"mb-3 border border-dark rounded\"\n  *ngIf=\"getInterpolationParams(entityChange.changeTime | date: 'medium') as params\"\n>\n  <button\n    type=\"button\"\n    class=\"btn btn-{{ color }} btn-block\"\n    (click)=\"isCollapsed = !isCollapsed\"\n    [attr.aria-expanded]=\"!isCollapsed\"\n    [attr.aria-controls]=\"uuid\"\n  >\n    {{ 'AbpAuditLogging::DaysAgoWithUserTitle' | abpLocalization: params }}\n  </button>\n  <div [id]=\"uuid\" [@collapse]=\"isCollapsed ? 'collapsed' : 'expanded'\">\n    <div class=\"m-3\">\n      <table class=\"table table-striped\">\n        <thead>\n          <tr>\n            <th>{{ 'AbpAuditLogging::PropertyName' | abpLocalization }}</th>\n            <th>{{ 'AbpAuditLogging::OriginalValue' | abpLocalization }}</th>\n            <th>{{ 'AbpAuditLogging::NewValue' | abpLocalization }}</th>\n          </tr>\n        </thead>\n        <tbody>\n          <tr *ngFor=\"let prop of entityChange.propertyChanges; trackBy: track.by('id')\">\n            <td>{{ prop.propertyName }}</td>\n            <td>{{ prop.originalValue }}</td>\n            <td [style.color]=\"getPropColor(prop)\">{{ prop.newValue }}</td>\n          </tr>\n        </tbody>\n      </table>\n    </div>\n  </div>\n</div>\n",
                animations: [collapse]
            },] }
];
EntityChangeDetailsComponent.ctorParameters = () => [
    { type: TrackByService }
];
EntityChangeDetailsComponent.propDecorators = {
    itemWithUserName: [{ type: Input }],
    isCollapsed: [{ type: Input }]
};

class EntityChangeModalComponent {
    constructor(cdRef, track) {
        this.cdRef = cdRef;
        this.track = track;
        this.history = [];
        this.visible = false;
    }
}
EntityChangeModalComponent.decorators = [
    { type: Component, args: [{
                selector: 'abp-entity-change-modal',
                template: "<abp-modal [(visible)]=\"visible\">\n  <ng-template #abpHeader>\n    <h5 class=\"modal-title\">\n      {{ entityTypeFullName }}\n      <br />\n      <span style=\"font-size: small\">({{ entityId }})</span>\n    </h5>\n  </ng-template>\n  <ng-template #abpBody>\n    <ng-container *ngIf=\"history.length; else noChanges\">\n      <abp-entity-change-details\n        *ngFor=\"let change of history; first as f; trackBy: track.byDeep('entityChange', 'id')\"\n        [itemWithUserName]=\"change\"\n        [isCollapsed]=\"!f\"\n      ></abp-entity-change-details>\n    </ng-container>\n\n    <ng-template #noChanges\n      ><div class=\"text-center\">\n        {{ 'AbpAuditLogging::NoChanges' | abpLocalization }}\n      </div></ng-template\n    >\n  </ng-template>\n  <ng-template #abpFooter>\n    <button class=\"btn btn-secondary\" abpClose>\n      {{ 'AbpAuditLogging::Close' | abpLocalization }}\n    </button>\n  </ng-template>\n</abp-modal>\n",
                changeDetection: ChangeDetectionStrategy.OnPush
            },] }
];
EntityChangeModalComponent.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: TrackByService }
];

class EntityChangeModalService {
    constructor(auditLogsService, contentProjectionService) {
        this.auditLogsService = auditLogsService;
        this.contentProjectionService = contentProjectionService;
        this.projectModal();
    }
    projectModal() {
        this.modalRef = this.contentProjectionService.projectContent(PROJECTION_STRATEGY.AppendComponentToBody(EntityChangeModalComponent));
        this.modalRef.changeDetectorRef.detectChanges();
    }
    detectChanges() {
        this.modalRef.changeDetectorRef.detectChanges();
        this.modalRef.instance.cdRef.detectChanges();
    }
    showDetails(entityChangeId) {
        this.auditLogsService.getEntityChangeWithUsername(entityChangeId).subscribe(change => {
            this.modalRef.instance.entityId = change.entityChange.entityId;
            this.modalRef.instance.entityTypeFullName = change.entityChange.entityTypeFullName;
            this.modalRef.instance.history = [change].filter(Boolean);
            this.modalRef.instance.visible = true;
            this.detectChanges();
        });
    }
    showHistory(entityId, entityTypeFullName) {
        this.auditLogsService
            .getEntityChangesWithUsername({ entityId, entityTypeFullName })
            .subscribe(changes => {
            this.modalRef.instance.entityId = entityId;
            this.modalRef.instance.entityTypeFullName = entityTypeFullName;
            this.modalRef.instance.history = changes;
            this.modalRef.instance.visible = true;
            this.detectChanges();
        });
    }
}
EntityChangeModalService.ɵprov = i0.ɵɵdefineInjectable({ factory: function EntityChangeModalService_Factory() { return new EntityChangeModalService(i0.ɵɵinject(i1.AuditLogsService), i0.ɵɵinject(i2.ContentProjectionService)); }, token: EntityChangeModalService, providedIn: "root" });
EntityChangeModalService.decorators = [
    { type: Injectable, args: [{
                providedIn: 'root',
            },] }
];
EntityChangeModalService.ctorParameters = () => [
    { type: AuditLogsService },
    { type: ContentProjectionService }
];

const ENTITY_DETAILS_PROVIDERS = [
    {
        provide: SHOW_ENTITY_DETAILS,
        useFactory: bindShowDetails,
        deps: [EntityChangeModalService],
    },
];
function bindShowDetails(service) {
    return service.showDetails.bind(service);
}

const ENTITY_HISTORY_PROVIDERS = [
    {
        provide: SHOW_ENTITY_HISTORY,
        useFactory: bindShowHistory,
        deps: [EntityChangeModalService],
    },
];
function bindShowHistory(service) {
    return service.showHistory.bind(service);
}

const AUDIT_LOGGING_ROUTE_PROVIDERS = [
    { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
function configureRoutes(routes) {
    return () => {
        routes.add([
            {
                name: "AbpAuditLogging::Menu:AuditLogging" /* AuditLogging */,
                path: '/audit-logs',
                parentName: "AbpUiNavigation::Menu:Administration" /* Administration */,
                layout: "application" /* application */,
                iconClass: 'fa fa-file-text',
                order: 6,
                requiredPolicy: "AuditLogging.AuditLogs" /* AuditLogging */,
            },
        ]);
    };
}

class AuditLoggingConfigModule {
    static forRoot() {
        return {
            ngModule: AuditLoggingConfigModule,
            providers: [
                AUDIT_LOGGING_ROUTE_PROVIDERS,
                ENTITY_DETAILS_PROVIDERS,
                ENTITY_HISTORY_PROVIDERS,
                AUDIT_LOGGING_FEATURES_PROVIDERS,
            ],
        };
    }
}
AuditLoggingConfigModule.decorators = [
    { type: NgModule, args: [{
                entryComponents: [EntityChangeModalComponent],
                declarations: [EntityChangeDetailsComponent, EntityChangeModalComponent],
                exports: [EntityChangeDetailsComponent, EntityChangeModalComponent],
                imports: [CoreModule, ThemeSharedModule],
            },] }
];

/**
 * Generated bundle index. Do not edit.
 */

export { AUDIT_LOGGING_ROUTE_PROVIDERS, AuditLoggingConfigModule, ENTITY_DETAILS_PROVIDERS, ENTITY_HISTORY_PROVIDERS, EntityChangeDetailsComponent, EntityChangeModalComponent, EntityChangeModalService, bindShowDetails, bindShowHistory, configureRoutes, EntityChangeModalComponent as ɵa, EntityChangeDetailsComponent as ɵb, AUDIT_LOGGING_ROUTE_PROVIDERS as ɵc, configureRoutes as ɵd, ENTITY_DETAILS_PROVIDERS as ɵe, ENTITY_HISTORY_PROVIDERS as ɵf };
