import { Injectable, Injector } from '@angular/core';
import { MultiTenancyService, RoutesService, ConfigStateService, SessionStateService, EnvironmentService, SubscriptionService, RouterEvents, getRoutePath, AbpTenantService } from '@abp/ng.core';
import { Router, ActivatedRoute } from '@angular/router';
import { map, finalize } from 'rxjs/operators';
import snq from 'snq';
import { __rest } from 'tslib';
import { ToasterService } from '@abp/ng.theme.shared';

class AuthWrapperService {
    constructor(injector) {
        this.injector = injector;
        this.tenantBoxKey = 'Account.TenantBoxComponent';
        this.logoKey = 'Account.LogoComponent';
        this.multiTenancy = injector.get(MultiTenancyService);
        this.router = injector.get(Router);
        this.route = injector.get(ActivatedRoute);
        this.routes = injector.get(RoutesService);
        this.configState = injector.get(ConfigStateService);
        this.sessionState = injector.get(SessionStateService);
        this.environment = injector.get(EnvironmentService);
        this.subscription = injector.get(SubscriptionService);
        this.routerEvents = injector.get(RouterEvents);
    }
    get isMultiTenancyEnabled$() {
        return this.configState.getDeep$('multiTenancy.isEnabled');
    }
    get languages$() {
        return this.configState.getDeep$('localization.languages');
    }
    get enableLocalLogin$() {
        return this.configState
            .getSetting$('Abp.Account.EnableLocalLogin')
            .pipe(map(value => (value === null || value === void 0 ? void 0 : value.toLowerCase()) !== 'false'));
    }
    get isTenantBoxVisible() {
        return this.tenantBoxVisible && this.multiTenancy.isTenantBoxVisible;
    }
    set isTenantBoxVisible(value) {
        this.multiTenancy.isTenantBoxVisible = value;
    }
    get defaultLanguage$() {
        return this.languages$.pipe(map(languages => {
            const lang = snq(() => languages.find(l => l.cultureName === this.selectedLangCulture), {});
            return {
                displayName: lang.displayName || '',
                flagIcon: lang.flagIcon,
            };
        }));
    }
    get dropdownLanguages$() {
        return this.languages$.pipe(map(languages => snq(() => languages.filter(lang => lang.cultureName !== this.selectedLangCulture)), []));
    }
    get selectedLangCulture() {
        return this.sessionState.getLanguage();
    }
    get appInfo() {
        return this.environment.getEnvironment().application;
    }
    get pageLabel() {
        const path = getRoutePath(this.router);
        const route = this.routes.search({ path });
        return (route === null || route === void 0 ? void 0 : route.name) || '';
    }
    get tenantBoxVisible() {
        var _a;
        return (_a = this.getMostInnerChild().data.tenantBoxVisible) !== null && _a !== void 0 ? _a : true;
    }
    onChangeLang(cultureName) {
        this.sessionState.setLanguage(cultureName);
    }
    getMostInnerChild() {
        let child = this.route.snapshot;
        let depth = 0;
        const depthLimit = 10;
        while (child.firstChild && depth < depthLimit) {
            child = child.firstChild;
            depth++;
        }
        return child;
    }
}
AuthWrapperService.decorators = [
    { type: Injectable }
];
AuthWrapperService.ctorParameters = () => [
    { type: Injector }
];

class TenantBoxService {
    constructor(toasterService, tenantService, sessionState, configState) {
        this.toasterService = toasterService;
        this.tenantService = tenantService;
        this.sessionState = sessionState;
        this.configState = configState;
        this.currentTenant$ = this.sessionState.getTenant$();
    }
    onSwitch() {
        const tenant = this.sessionState.getTenant();
        this.name = tenant === null || tenant === void 0 ? void 0 : tenant.name;
        this.isModalVisible = true;
    }
    save() {
        if (!this.name) {
            this.setTenant(null);
            this.isModalVisible = false;
            return;
        }
        this.modalBusy = true;
        this.tenantService
            .findTenantByName(this.name, {})
            .pipe(finalize(() => (this.modalBusy = false)))
            .subscribe((_a) => {
            var { success, isActive, tenantId: id } = _a, tenant = __rest(_a, ["success", "isActive", "tenantId"]);
            if (!success || !isActive) {
                this.showError();
                return;
            }
            this.setTenant(Object.assign(Object.assign({}, tenant), { id, isAvailable: true }));
            this.isModalVisible = false;
        });
    }
    setTenant(tenant) {
        this.sessionState.setTenant(tenant);
        this.configState.refreshAppState().subscribe();
    }
    showError() {
        this.toasterService.error('AbpUiMultiTenancy::GivenTenantIsNotAvailable', 'AbpUi::Error', {
            messageLocalizationParams: [this.name],
        });
    }
}
TenantBoxService.decorators = [
    { type: Injectable }
];
TenantBoxService.ctorParameters = () => [
    { type: ToasterService },
    { type: AbpTenantService },
    { type: SessionStateService },
    { type: ConfigStateService }
];

/*
 * Public API Surface of account-core
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AuthWrapperService, TenantBoxService };
