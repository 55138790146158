import { RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER, NgModule } from '@angular/core';

const SAAS_ROUTE_PROVIDERS = [
    { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
function configureRoutes(routes) {
    return () => {
        routes.add([
            {
                path: '/saas',
                name: "Saas::Menu:Saas" /* Saas */,
                // TODO: let people know about this number
                order: 40,
                layout: "application" /* application */,
                iconClass: 'fa fa-users',
                requiredPolicy: "Saas.Tenants || Saas.Editions" /* Saas */,
            },
            {
                path: '/saas/tenants',
                name: "Saas::Tenants" /* Tenants */,
                parentName: "Saas::Menu:Saas" /* Saas */,
                order: 1,
                requiredPolicy: "Saas.Tenants" /* Tenants */,
            },
            {
                path: '/saas/editions',
                name: "Saas::Editions" /* Editions */,
                parentName: "Saas::Menu:Saas" /* Saas */,
                order: 2,
                requiredPolicy: "Saas.Editions" /* Editions */,
            },
        ]);
    };
}

class SaasConfigModule {
    static forRoot() {
        return {
            ngModule: SaasConfigModule,
            providers: [SAAS_ROUTE_PROVIDERS],
        };
    }
}
SaasConfigModule.decorators = [
    { type: NgModule }
];

/**
 * Generated bundle index. Do not edit.
 */

export { SAAS_ROUTE_PROVIDERS, SaasConfigModule, configureRoutes, SAAS_ROUTE_PROVIDERS as ɵa, configureRoutes as ɵb };
