import { APP_INITIALIZER, NgModule } from '@angular/core';
import { LANGUAGE_MANAGEMENT_FEATURES_PROVIDERS } from '@volo/abp.ng.language-management/common';
import { RoutesService } from '@abp/ng.core';

const LANGUAGE_MANAGEMENT_ROUTE_PROVIDERS = [
    { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
function configureRoutes(routes) {
    return () => {
        routes.add([
            {
                name: "LanguageManagement::LanguageManagement" /* LanguageManagement */,
                path: '/language-management',
                layout: "application" /* application */,
                parentName: "AbpUiNavigation::Menu:Administration" /* Administration */,
                iconClass: 'fa fa-globe',
                order: 4,
                requiredPolicy: "LanguageManagement.Languages || LanguageManagement.LanguageTexts" /* LanguageManagement */,
            },
            {
                path: '/language-management/languages',
                name: "LanguageManagement::Languages" /* Languages */,
                parentName: "LanguageManagement::LanguageManagement" /* LanguageManagement */,
                order: 1,
                requiredPolicy: "LanguageManagement.Languages" /* Languages */,
            },
            {
                path: '/language-management/texts',
                name: "LanguageManagement::LanguageTexts" /* LanguageTexts */,
                parentName: "LanguageManagement::LanguageManagement" /* LanguageManagement */,
                order: 2,
                requiredPolicy: "LanguageManagement.LanguageTexts" /* LanguageTexts */,
            },
        ]);
    };
}

class LanguageManagementConfigModule {
    static forRoot() {
        return {
            ngModule: LanguageManagementConfigModule,
            providers: [LANGUAGE_MANAGEMENT_ROUTE_PROVIDERS, LANGUAGE_MANAGEMENT_FEATURES_PROVIDERS],
        };
    }
}
LanguageManagementConfigModule.decorators = [
    { type: NgModule }
];

/**
 * Generated bundle index. Do not edit.
 */

export { LANGUAGE_MANAGEMENT_ROUTE_PROVIDERS, LanguageManagementConfigModule, configureRoutes, LANGUAGE_MANAGEMENT_ROUTE_PROVIDERS as ɵa, configureRoutes as ɵb };
