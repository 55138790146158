import { RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TEXT_TEMPLATE_MANAGEMENT_FEATURES_PROVIDERS } from '@volo/abp.ng.text-template-management/common';

const TEXT_TEMPLATE_MANAGEMENT_ROUTE_PROVIDERS = [
    { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
function configureRoutes(routes) {
    return () => {
        routes.add([
            {
                name: "TextTemplateManagement::Menu:TextTemplates" /* TextTemplates */,
                path: '/text-template-management/text-templates',
                parentName: "AbpUiNavigation::Menu:Administration" /* Administration */,
                order: 5,
                layout: "application" /* application */,
                requiredPolicy: "TextTemplateManagement.TextTemplates" /* TextTemplates */,
                iconClass: 'fas fa-envelope-open-text',
            },
        ]);
    };
}

class TextTemplateManagementConfigModule {
    static forRoot() {
        return {
            ngModule: TextTemplateManagementConfigModule,
            providers: [
                TEXT_TEMPLATE_MANAGEMENT_ROUTE_PROVIDERS,
                TEXT_TEMPLATE_MANAGEMENT_FEATURES_PROVIDERS,
            ],
        };
    }
}
TextTemplateManagementConfigModule.decorators = [
    { type: NgModule }
];

/**
 * Generated bundle index. Do not edit.
 */

export { TEXT_TEMPLATE_MANAGEMENT_ROUTE_PROVIDERS, TextTemplateManagementConfigModule, configureRoutes, TEXT_TEMPLATE_MANAGEMENT_ROUTE_PROVIDERS as ɵa, configureRoutes as ɵb };
