import * as i1 from '@abp/ng.core';
import { RestService, ConfigStateService, noop, CoreModule, mapEnumToOptions, ExtensibleCreationAuditedEntityDto, PagedAndSortedResultRequestDto, WebHttpUrlEncodingCodec, EnvironmentService } from '@abp/ng.core';
import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Injector, APP_INITIALIZER, NgModule, inject } from '@angular/core';
import { map, filter, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import * as i2 from '@angular/common/http';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import * as i3 from 'angular-oauth2-oidc';
import { OAuthService } from 'angular-oauth2-oidc';
import { __rest } from 'tslib';

const DEFAULT_PROFILE_ICON = {
    type: 'icon',
    source: 'fas fa-user bg-light',
};

const PROFILE_PICTURE = new InjectionToken('PROFILE_PICTURE', {
    providedIn: 'root',
    factory: () => new BehaviorSubject({ type: 'image', source: null }),
});

class ProfilePictureService {
    constructor(restService, injector, configState) {
        this.restService = restService;
        this.injector = injector;
        this.configState = configState;
        this.url = '/api/account-admin/settings';
        this.publicApiName = 'AbpAccountPublic';
        this.getProfilePicture = (id, skipHandleError = false) => this.restService.request({
            method: 'GET',
            url: `/api/account/profile-picture/${id}`,
        }, {
            apiName: this.publicApiName,
            skipHandleError,
        });
        this.setProfilePicture = (input) => this.restService.request({
            method: 'POST',
            url: `/api/account/profile-picture`,
            body: input,
        }, { apiName: this.publicApiName });
        this.listenToAppConfig();
    }
    listenToAppConfig() {
        const profilePicture$ = this.injector.get(PROFILE_PICTURE);
        this.configState
            .createOnUpdateStream(state => state)
            .pipe(map(() => this.configState.getDeep('currentUser.id')), filter(id => id !== this.currentUserId), tap(id => {
            this.currentUserId = id;
            if (!id) {
                profilePicture$.next(null);
            }
        }), filter(id => !!id), switchMap(id => this.getProfilePicture(id, true)))
            .subscribe(res => {
            profilePicture$.next({
                type: 'image',
                source: res.source || `data:image/png;base64,${res.fileContent}`,
            });
        }, () => {
            profilePicture$.next(DEFAULT_PROFILE_ICON);
        });
    }
}
ProfilePictureService.ɵprov = i0.ɵɵdefineInjectable({ factory: function ProfilePictureService_Factory() { return new ProfilePictureService(i0.ɵɵinject(i1.RestService), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i1.ConfigStateService)); }, token: ProfilePictureService, providedIn: "root" });
ProfilePictureService.decorators = [
    { type: Injectable, args: [{
                providedIn: 'root',
            },] }
];
ProfilePictureService.ctorParameters = () => [
    { type: RestService },
    { type: Injector },
    { type: ConfigStateService }
];

const ɵ0 = noop;
const PROFILE_PICTURE_PROVIDERS = [
    {
        provide: APP_INITIALIZER,
        multi: true,
        deps: [ProfilePictureService],
        useFactory: ɵ0,
    },
];

class CommercialUiConfigModule {
    static forRoot() {
        return {
            ngModule: CommercialUiConfigModule,
            providers: [PROFILE_PICTURE_PROVIDERS],
        };
    }
}
CommercialUiConfigModule.decorators = [
    { type: NgModule, args: [{
                imports: [CoreModule],
                exports: [],
                declarations: [],
            },] }
];

var eProfilePictureType;
(function (eProfilePictureType) {
    eProfilePictureType[eProfilePictureType["None"] = 0] = "None";
    eProfilePictureType[eProfilePictureType["Gravatar"] = 1] = "Gravatar";
    eProfilePictureType[eProfilePictureType["Image"] = 2] = "Image";
})(eProfilePictureType || (eProfilePictureType = {}));
const profilePictureTypeOptions = mapEnumToOptions(eProfilePictureType);

class IdentitySecurityLogDto extends ExtensibleCreationAuditedEntityDto {
    constructor(initialValues = {}) {
        super(initialValues);
    }
}
class IdentitySecurityLogGetListInput extends PagedAndSortedResultRequestDto {
}

class IdentityLinkUserService {
    constructor(restService, http, oAuthService) {
        this.restService = restService;
        this.http = http;
        this.oAuthService = oAuthService;
        this.apiName = 'AbpIdentity';
        this.generateLinkToken = () => this.restService.request({
            method: 'POST',
            responseType: 'text',
            url: `/api/identity/link-user/generate-link-token`,
        }, { apiName: this.apiName });
        this.getAllList = () => this.restService.request({
            method: 'GET',
            url: `/api/identity/link-user`,
        }, { apiName: this.apiName });
        this.isLinked = (input) => this.restService.request({
            method: 'POST',
            url: `/api/identity/link-user/is-linked`,
            body: input,
        }, { apiName: this.apiName });
        this.link = (input) => this.restService.request({
            method: 'POST',
            url: `/api/identity/link-user/link`,
            body: input,
        }, { apiName: this.apiName });
        this.unlink = (input) => this.restService.request({
            method: 'POST',
            url: `/api/identity/link-user/unlink`,
            body: input,
        }, { apiName: this.apiName });
        this.verifyLinkToken = (token) => this.restService.request({
            method: 'POST',
            url: `/api/identity/link-user/verify-link-token`,
            body: token,
        }, { apiName: this.apiName });
    }
    linkLogin(input) {
        const { tokenEndpoint, clientId, dummyClientSecret, scope } = this.oAuthService;
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        let params = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() })
            .set('grant_type', 'LinkLogin')
            .set('LinkUserId', input.linkUserId)
            .set('access_token', this.oAuthService.getAccessToken())
            .set('client_id', clientId)
            .set('client_secret', dummyClientSecret)
            .set('scope', scope);
        if (input.linkTenantId) {
            params = params.set('LinkTenantId', input.linkTenantId);
        }
        return this.http.post(tokenEndpoint, params, {
            headers,
        });
    }
}
IdentityLinkUserService.ɵprov = i0.ɵɵdefineInjectable({ factory: function IdentityLinkUserService_Factory() { return new IdentityLinkUserService(i0.ɵɵinject(i1.RestService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.OAuthService)); }, token: IdentityLinkUserService, providedIn: "root" });
IdentityLinkUserService.decorators = [
    { type: Injectable, args: [{
                providedIn: 'root',
            },] }
];
IdentityLinkUserService.ctorParameters = () => [
    { type: RestService },
    { type: HttpClient },
    { type: OAuthService }
];

const NAVIGATE_TO_MY_SECURITY_LOGS = new InjectionToken('NAVIGATE_TO_MY_SECURITY_LOGS', {
    providedIn: 'root',
    factory: () => {
        const environment = inject(EnvironmentService);
        return () => {
            window.open(`${environment.getEnvironment().oAuthConfig.issuer}/Account/SecurityLogs?returnUrl=${window.location.href}`, '_self');
        };
    },
});

const OPEN_MY_LINK_USERS_MODAL = new InjectionToken('OPEN_MY_LINK_USERS_MODAL');

function setModuleVisibilityFactory(stream, service, identifier) {
    return stream.pipe(map(features => features.enable), tap(enable => {
        const _a = service.find(r => r.name === identifier), { children } = _a, tree = __rest(_a, ["children"]);
        tree.invisible = !enable;
        service.patch(identifier, tree);
    }));
}

/**
 * Generated bundle index. Do not edit.
 */

export { CommercialUiConfigModule, DEFAULT_PROFILE_ICON, IdentityLinkUserService, IdentitySecurityLogDto, IdentitySecurityLogGetListInput, NAVIGATE_TO_MY_SECURITY_LOGS, OPEN_MY_LINK_USERS_MODAL, PROFILE_PICTURE, PROFILE_PICTURE_PROVIDERS, ProfilePictureService, eProfilePictureType, profilePictureTypeOptions, setModuleVisibilityFactory, ɵ0, PROFILE_PICTURE_PROVIDERS as ɵa, ProfilePictureService as ɵb };
