import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RoutesService } from '@abp/ng.core';

const IDENTITY_SERVER_ROUTE_PROVIDERS = [
    { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
function configureRoutes(routes) {
    return () => {
        routes.add([
            {
                name: "AbpIdentityServer::Menu:IdentityServer" /* IdentityServer */,
                path: '/identity-server',
                parentName: "AbpUiNavigation::Menu:Administration" /* Administration */,
                layout: "application" /* application */,
                iconClass: 'fa fa-id-card-o',
                order: 3,
                requiredPolicy: "IdentityServer.Client || IdentityServer.IdentityResource || IdentityServer.ApiResource" /* IdentityServer */,
            },
            {
                path: '/identity-server/clients',
                name: "AbpIdentityServer::Menu:Clients" /* Clients */,
                parentName: "AbpIdentityServer::Menu:IdentityServer" /* IdentityServer */,
                order: 1,
                requiredPolicy: "IdentityServer.Client" /* Clients */,
            },
            {
                path: '/identity-server/identity-resources',
                name: "AbpIdentityServer::Menu:IdentityResources" /* IdentityResources */,
                parentName: "AbpIdentityServer::Menu:IdentityServer" /* IdentityServer */,
                order: 2,
                requiredPolicy: "IdentityServer.IdentityResource" /* IdentityResources */,
            },
            {
                path: '/identity-server/api-resources',
                name: "AbpIdentityServer::Menu:ApiResources" /* ApiResources */,
                parentName: "AbpIdentityServer::Menu:IdentityServer" /* IdentityServer */,
                order: 3,
                requiredPolicy: "IdentityServer.ApiResource" /* ApiResources */,
            },
            {
                path: '/identity-server/api-scopes',
                name: "AbpIdentityServer::Menu:ApiScopes" /* ApiScopes */,
                parentName: "AbpIdentityServer::Menu:IdentityServer" /* IdentityServer */,
                order: 4,
                requiredPolicy: "IdentityServer.ApiScope" /* ApiScopes */,
            },
        ]);
    };
}

class IdentityServerConfigModule {
    static forRoot() {
        return {
            ngModule: IdentityServerConfigModule,
            providers: [IDENTITY_SERVER_ROUTE_PROVIDERS],
        };
    }
}
IdentityServerConfigModule.decorators = [
    { type: NgModule }
];

/**
 * Generated bundle index. Do not edit.
 */

export { IDENTITY_SERVER_ROUTE_PROVIDERS, IdentityServerConfigModule, configureRoutes, IDENTITY_SERVER_ROUTE_PROVIDERS as ɵa, configureRoutes as ɵb };
